.main-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1rem;
  padding-left: 1rem;

  &.max-2\/3 {
    max-width: 800px;
  }

  &.max-4\/5 {
    max-width: 960px;
  }
}

.page-section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &-extra-pt {
    padding-top: 2.5rem;
  }
}

.hero {
  padding: 3rem 0;
  text-align: center;

  .page-heading {
    max-width: 800px;
    margin: 0 auto;
  }

  &.centered {
    text-align: center;
  }
}

.empty-state,
.under-construction {
  font-size: 1.125rem;
  background-color: var(--sky-blue-20);
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  @media screen and (min-width: 920px) {
    padding: 60px;
    text-align: center;
  }
}

.under-construction {
  margin-top: 6.5rem;
}

summary {
  cursor: pointer;
}

@media screen and (min-width: 840px) {
  .page-section {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &-extra-pt {
      padding-top: 6.5rem;
    }
  }

  .page-section-extra-pt .under-construction {
    margin-top: 0;
  }

  .two-cols {
    display: grid;
    gap: 0 1rem;
    grid-template-columns: 2fr 1fr;
  }

  .two-equal-cols {
    display: grid;
    gap: 0 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .gap-2 {
    gap: 0 2rem;
  }

  .gap-4 {
    gap: 0 4rem;
  }

  .gap-6 {
    gap: 0 6rem;
  }

  .hero {
    padding: 5rem 0 3rem;
  }

  .map {
    &-cols {
      display: grid;
      gap: 0 1rem;
      grid-template-columns: 3fr 5fr;
      grid-template-areas: 'main figure';
    }

    &-figure {
      grid-area: figure;
    }

    &-text {
      grid-area: main;
    }
  }
}
