.charts-display {
  min-height: 100%;
}

.charts-display-hero {
  position: relative;
  padding: 4rem 0 6rem;
  margin-bottom: -2rem;
  margin-bottom: -5rem;
  background: var(--blue-gradient-3);

  > .main-container {
    position: relative;
    text-align: center;
  }

  .page-heading {
    margin: 0 0 .5rem;
  }

  .header-text {
    margin: 0 auto;
    max-width: 90ch;
    font-size: .95rem;
    text-align: center;
  }

  .header-text summary {
    font-weight: 600;
  }

  .header-text + .share-button {
    margin-top: 1rem;
  }

  .alt {
    background: var(--green-30);
  }

  @media screen and (min-width: 1040px) {
    padding: 5rem 2.5rem 6rem;
    margin-bottom: -6rem;

    .share-button {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}