.posts-hero {
  background-image: var(--blue-gradient-4);
  padding-bottom: 4rem;
}

.post-list {
  padding: 1.5rem;
  background-color: var(--white);
}

.post-list-header {
  display: flex;
  align-items: center;
  gap: .75rem;

  margin-bottom: 1rem;
}

.post-list-header img {
  height: 64px;
}

.post-list .lead {
  margin-bottom: 0;
  font-size: 1.4rem;
  color: var(--stone);
  opacity: .6;
  text-decoration: underline;
}
