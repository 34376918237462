html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}

body {
  display: flex;
  flex-flow: column;
}

.main-content {
  flex: 1;
}

*, *:before, *:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

hr {
  margin: 1rem 0;
  border: 0 none;
  border-top: 1px solid var(--border-gray);
}

.bg-red { background-color: var(--red);}
.bg-gray { background-color: var(--gray);}
.bg-off-pearl { background-color: var(--off-pearl);}
.bg-stone { background-color: var(--stone);}
.bg-blue { background-color: var(--blue);}
.bg-blue-chill { background-color: var(--blue-chill);}
.bg-blue-chill-10 { background-color: var(--blue-chill-10);}
.bg-green { background-color: var(--green);}
.bg-white { background-color: var(--white);}
.bg-off-black { background-color: var(--off-black);}
.bg-sky-blue { background-color: var(--sky-blue);}
.bg-sky-blue-20 { background-color: var(--sky-blue-20);}
.bg-border-gray { background-color: var(--border-gray);}

.is-red { color: var(--red);}
.is-gray { color: var(--gray);}
.is-gray-60 { color: var(--gray-60);}
.is-off-pearl { color: var(--off-pearl);}
.is-stone { color: var(--stone);}
.is-blue { color: var(--blue);}
.is-blue-chill { color: var(--blue-chill);}
.is-green { color: var(--green);}
.is-white { color: var(--white);}
.is-off-black { color: var(--off-black);}
.is-sky-blue { color: var(--sky-blue);}
.is-border-gray { color: var(--border-gray);}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.text-center {
  text-align: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.hidden {
  display: none !important;
}

.hide-on-mobile {
  display: none;
}

@media (min-width: 1040px) {
  .hide-on-desktop {
    display: none;
  }

  .hide-on-mobile {
    display: block;
  }
}