:root {
  --red: #BA0E13;
  --red-shade: #AD202E;
  --gray: #4F4F51;
  --off-pearl: #E7E1D3;
  --off-pearl-20: #FAF9F6;
  --stone: #1B2A46;
  --stone-80: rgba(27, 42, 70, 0.8);
  --blue: #00608F;
  --blue-chill: #108787;
  --blue-chill-05: rgba(16, 135, 135, 0.05);
  --blue-chill-10: #E7F3F3;
  --blue-chill-30: rgba(16, 135, 135, 0.3);
  --green: #49AD76;
  --green-30: rgba(185,226,203,0.3);
  --white: #FFF;
  --off-black: #242424;
  --sky-blue: #B2DCF0;
  --sky-blue-20: rgba(178, 220, 240, 0.2);
  --border-gray: #B7BBC1;
  --border-gray-light: rgba(79, 79, 81, 0.2);
  --gray-60: rgba(41, 41, 41, 0.6);
  --blue-gradient-1: linear-gradient(90deg, #00608F -0.82%, rgba(0, 96, 143, 0.4) 100.56%);
  --blue-gradient-2: linear-gradient(90deg, rgba(0, 96, 143, 0.12) -0.82%, rgba(0, 96, 143, 0.04) 100.56%);
  --blue-gradient-3: linear-gradient(180deg, rgba(0, 96, 143, 0.18) -68.07%, rgba(0, 96, 143, 0.06) 87.14%);
  --blue-gradient-4: linear-gradient(0deg, rgba(0, 96, 143, 0.18) -0.82%, rgba(0, 96, 143, 0.1) 100.56%);

  --font-family: 'PT Sans', sans-serif;
  --alt-font-family: 'US101regular', sans-serif;
  --heading-font-family: 'NewsGothic', sans-serif;
  --font-size-base: 1rem;
  --text-color: var(--off-black);

  --heading-color: var(--off-black);
  --link-color: var(--blue);
  --link-underline-color: var(--blue-chill);
}
