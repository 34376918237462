.body-about {
  .site-footer {
    margin-top: 0;
  }
}

.partner {
  display: grid;
  grid-template-areas:
   "img",
   "content";
}

.partner + .partner {
  margin-top: 2rem;
}

.partner h3:first-child {
  margin-top: 0;
}

.partner img {
  max-height: 120px;
  width: auto;
}

.faq {
  margin: 2rem 0 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--border-gray);

  &:last-child {
    border-bottom: none;
  }

  summary h3 {
    display: inline-block;
    margin: 0;
  }

  .faq-body {
    margin-top: 1rem;
  }

  .faq-body a {
    @include link-effects;
  }
}

@media (min-width: 840px) {
  .partner {
    grid-template-areas:
      "img content";
    grid-template-columns: auto 1fr;
    gap: 0 2rem;
  }

  .partner img {
    grid-area: img;
  }

  .partner.content-first {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "content img";
  }

  .partner + .partner {
    margin-top: 4rem;
  }

  #bhhc_logo {
    max-height: 180px;
  }

  #uhc_logo {
    max-height: 100px;
  }
}
