.alert {
  width: 100%;

  p {
    margin-bottom: 0;
    padding: .5rem .5rem .5rem 1rem;
    border-left: 4px solid var(--blue);
    background-color: var(--stone);
    font-size: .9rem;
    color: #FFF;
  }
}