.field + .field {
  margin-top: 1rem;
}

.field.small {
  width: 280px;

  &.center {
    margin: 0 auto;
  }

}

.field.full {
  width: 100%;
}

.field.error {
  input:not([type=submit]):not([type=checkbox]):not([type=radio]):not([type=file]):not(.vs__search),  // Avoid messing uo with v-select component
  select,
  textarea,
  .select {
    border-color: var(--red);
    box-shadow: 0 0 2px var(--red);
  }
}

.form-errors {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid var(--red);
  border-left-width: 4px;
  background-color: var(--off-pearl);

  ul {
    margin: 0;
    padding: 0 0 0 1rem;
  }

  &.light {
    background-color: var(--off-pearl-20);
  }
}

.error-message {
  color: var(--red);
}

.field-group {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;

  .field {
    flex: 1;
  }

  .field + .field {
    margin-top: 0;
  }
}


label,
.label {
  font-size: 12px;
  font-size: 0.75rem;
  opacity: 0.6;
  font-family: var(--heading-font-family);
  font-weight: 600;
  text-transform: uppercase;

  &.required {
    font-weight: 700;
  }
}

input:not([type=submit]):not([type=checkbox]):not([type=radio]):not([type=file]):not(.vs__search),  // Avoid messing uo with v-select component
select,
textarea,
.select {
  appearance: none;
  box-sizing: border-box;
  padding: 0.5rem;
  width: 100%;
  height: 40px;
  box-shadow: none;
  border: 2px solid var(--border-gray);
  background-color: var(--field-background, white);
  font-size: var(--field-font-size);
  font-family: var(--heading-font-family);
  font-weight: 600;
  color: var(--gray-60);

  &:focus {
    box-shadow: 0 0 2px var(--blue);
    border-color: var(--text-color);
    outline: none;
  }


  &:invalid:focus {
    border-color: var(--red);
    box-shadow: 0 0 2px var(--red);
  }

  &[required] + label:after  {
    content: "*";
  }
}

select,
.select {
  padding-right: 40px;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E %3Cpath d='M11.255 9L17 14.585 22.745 9 24 10.195 17 17l-7-6.805L11.255 9z' fill='%231B2A46'/%3E %3Cpath stroke='%23B7BBC1' stroke-width='1.5' d='M.75 0v24'/%3E %3C/svg%3E ");
  background-position: center right 10px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  min-height: 40px;
  height: auto;
  line-height: 1.2;

  option {
    font-weight: normal;
  }

  &:hover {
    text-decoration: none;
  }
  &[multiple] {
    padding-right: .5rem;
    background-image: none;
  }
}

textarea {
  min-height: 5rem;
}
