.button {
  display: inline-flex;
  align-items: center;
  appearance: none;
  padding: 0.5rem 1rem 0.25rem;
  background: var(--blue);
  border: 2px solid transparent;
  border-radius: 3px;
  color: var(--white);
  font-family: var(--heading-font-family);
  font-size: 0.813rem;
  text-transform: uppercase;
  line-height: 1;

  &:not(:disabled):hover {
    cursor: pointer;
    border-color: var(--blue);
    background: var(--white);
    text-decoration: none;
    color: var(--blue);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.outline {
    border-color: var(--blue);
    background: var(--white);
    color: var(--blue);
    border-radius: 3px;
    text-transform: none;

    &:hover {
      background: var(--blue);
      color: white;
    }
  }

  &.hollow {
    border-color: var(--blue);
    background: var(--white);
    color: var(--blue);

    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }

  &.stone {
    border-color: var(--stone);
    background: var(--stone);


    &:not(:disabled):hover {
      border-color: var(--stone);
      color: var(--stone);
    }
  }

  &.chill {
    border-color: var(--blue-chill);
    background: var(--blue-chill);


    &:not(:disabled):hover {
      border-color: var(--blue-chill);
      color: var(--blue-chill);
    }
  }

  &.large {
    font-size: 1rem;
    text-transform: none;
    padding: 1rem 3rem 0.625rem;
  }

  &.form {
    height: 40px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  svg {
    margin: -4px 4px 0 -4px;
  }
}

.share-button,
.close-button,
.icon-button,
.social-button,
.link-button {
  appearance: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  border: 0 none;
  background: transparent;
  cursor: pointer;
  color: var(--blue);
  line-height: 1;

  &:hover {
    color: var(--stone);
  }

  &.is-red {
    color: var(--red);

    &:hover {
      color: var(--red-shade);
    }
  }
}

.link-button {
  font-size: 12px;
  font-size: 0.75rem;
  font-family: var(--heading-font-family);
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    text-decoration-skip: skip;
  }

  &.disabled {
    color: var(--gray-60);
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
}

.social-button {
  color: var(--stone);
  font-weight: bold;
  font-family: var(--heading-font-family);
  font-size: 1.125rem;

  svg {
    margin-right: 1rem;
  }
}

.share-button {
  color: var(--blue);
  font-weight: 700;
  font-family: var(--heading-font-family);

  svg {
    width: 20px;
    margin-right: 8px;
    margin-top: -4px;
  }
}

.close-button {
  font: 0/0 a;
  color: transparent;

  &:after {
    font: 32px/1 'sans-serif';
    color: var(--red);
    content: '×';
  }

  &-small:after {
    font: 20px/1 'sans-serif';
    font-weight: bold;
  }
}