.site-footer {
  background-color: var(--stone);
  margin-top: 4rem;
  padding: 2rem 0 1rem;

  &-nav-list {
    list-style: none;
    margin: 0 0 2rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    max-width: 70ch; // Roughly breaks after "View a Metric"
  }

  &-nav-link {
    display: flex;
    font-family: var(--heading-font-family);
    font-weight: 600;
    color: var(--white);
    text-decoration: none;
    padding: 0.25rem 1rem;
    margin: 0 -1rem;
    flex: 0 0 auto;
    font-size: 1.125rem;

    &:hover {
      text-decoration: none;
      background: rgba(255,255,255,0.05);
    }

    abbr {
      text-decoration: none;
    }
  }

  &-logo {
    display: block;
    max-width: 180px;
    margin: 1rem 0 0.5rem;
  }
}

.copyright {
  font-size: 0.7em;
  color: var(--white);
  opacity: 0.7;
}


@media screen and (min-width: 840px) {
  .site-footer {
    &-nav-list {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-nav-item {
      flex: 0 0 auto;
    }

    &-nav-link {
      padding: 0.25rem 2rem 0.25rem 0;
      margin: 0;

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }

    &-logo {
      max-width: 280px;
      margin: 0 0 0 auto;
    }
  }
}
