.site-header {
  position: relative;
  background: var(--stone);
  padding: 10px 0;
  margin-bottom: 60px;

  &.has-hero-below {
    margin-bottom: 0;
  }

  &:after {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 10px;
    background: var(--blue-gradient-1);
    opacity: 0.2;
    content: '';
  }

  &-layout {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.site-nav,
.close-nav {
  display: none;
}

.site-nav {
  &-logo {
    position: relative;
    z-index: 1;
    align-self: flex-start;
    width: 100px;
    flex: 0 0 auto;
    margin-bottom: -60px;
  }

  &-list {
    display: flex;
    list-style: none;
    margin: 0 auto;
    padding: 0;
  }

  &-item {
    font-family: var(--alt-font-family);
  }

  &-link {
    display: block;
    padding: 0.5rem;
    font-size: 22px;
    text-decoration: none;
    color: var(--white);

    &:hover {
      text-decoration: none;
      background: rgba(255,255,255,0.05);
    }

    &.is-current {
      background-color: var(--red);

      &:hover {
        background-color: var(--red);
      }
    }

    abbr {
      text-decoration: none;
    }
  }

  &-metadata {
    padding: 0.5rem;

    small {
      opacity: 0.8;
    }

    p,
    a {
      color: var(--white);
      margin: 0;
      font-size: 14px;
      line-height: 1.2;
    }
  }

  &.is-expanded {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--stone);
    z-index: 2;
    overflow: auto;

    .site-nav-wrap {
      margin: auto;
      padding-top: 48px;
      padding-bottom:48px;
      width: 100%;
      max-width: 300px;
    }

    .site-nav-list {
      flex-direction: column;
    }

    .close-nav {
      display: block;
      position: fixed;
      top: 10px;
      left: 10px;
    }
  }

  &-toggle,
  &.is-expanded .close-nav {
    width: 44px;
    height: 50px;
    margin: 0 auto 0 0;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    appearance: none;
    border: 0 none;
    background: none;
    cursor: pointer;

    svg {
      width: 100%;
      height: auto;
    }

    span {
      color: white;
      font-size: 11px;
      font-weight: bold;
    }

    &:hover {
      background: rgba(255,255,255,0.05);
    }
  }

  &.is-expanded .close-nav {
    height: 40px;
    padding: 6px;
  }
}

@media screen and (min-width: 1024px) {
  .site-header {
    padding: 20px 0;
  }

  .site-nav {
    &,
    &-metadata,
    &-wrap {
      display: flex;
    }

    &,
    &-wrap {
      flex: 1 1 100%;
    }

    &-wrap {
      padding-right: 20px;
      justify-content: center;
      align-items: center;
    }

    &-logo {
      width: 120px;
      margin-bottom: -80px;
    }

    &-toggle {
      display: none;
    }

    &-list {
      margin: 0;
    }

    &-item {
      & + & {
        margin-left: 0.8rem;
      }
    }

    &-link {
      padding: 0 0.5rem;
    }
  }
}
