.explore-header {
  padding-bottom: 1rem;
}

.explore-header-col2 {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .share-button {
    margin-left: 1rem;
  }
}


.explore-heading-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    margin-left: 1rem;
    transform: translateY(-4px); /* because TradeGothic has a dumb baseline */
  }
}

@media screen and (min-width: 840px) {
  .explore-heading-wrap {
    justify-content: flex-start;

    .button {
      margin-left: 40px;
      transform: translateY(-8px); /* because TradeGothic has a dumb baseline */
    }
  }

  .explore-header-col2 .share-button {
    margin-left: 60px;
  }
}
