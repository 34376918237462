.home-hero {
  background: url(/img/home-hero-bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  text-align: center;

  .page-heading {
    max-width: 800px;
    margin: 0 auto;
  }
}

.home-callout {
  &-list {
    list-style: none;
    margin: -1rem 0 1rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &-item {
    display: flex;
    justify-content: center;

    margin-bottom: 1rem;

    p {
      margin: 0 auto;
      max-width: 26ch;
      font-family: var(--heading-font-family);
      font-weight: 600;
      color: var(--white);
      font-size: 1.125rem;
      opacity: 0.8;
      margin: 1rem 0;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1rem;
      width: 100%;
      text-align: center;
      line-height: 1.2;
    }

    a:hover {
      text-decoration: none;
    }
  }

  &-heading {
    font-family: var(--alt-font-family);
    color: var(--white);
    font-size: 1.375rem;
    margin: 1rem 0;
  }
}

.posts-feed {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }

  .post {
    padding: 1.25rem 0;
  }

  .post-heading {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 480px) {
  .home-callout {

    &-list {
      flex-direction: row;
      justify-content: space-between;
    }

    &-item {
      width: 32%;
    }
  }
}


@media screen and (min-width: 840px) {
  .home-hero {
    padding: 60px 0 120px;
  }
}

.home-modal-buttons {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .button {
    width: 200px;
    justify-content: center;
  }
}