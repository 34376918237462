@mixin link-effects {
  background-color: var(--blue-chill-05);
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-skip: skip;

  &:hover {
    background-color: transparent;
    text-decoration: none;
  }
}