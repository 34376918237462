.body-download {
  .page-heading {
    text-align: center;
  }
}

.terms {
  &-accept {
    padding: 20px 20px 14px;
    background-color: var(--off-pearl-20);
    width: 100%;

    label {
      color: var(--blue-chill);
      font-size: 1.125rem;
      line-height: 1;
      font-weight: 600;
      text-transform: none;
      margin-left: 10px;
      opacity: 1;
    }
  }
}

.terms-legal {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid var(--border-gray);
  max-height: 18rem;
  overflow-y: scroll;
}

@media screen and (min-width: 920px) {
  .terms {
    padding: 0 220px;
  }
}
