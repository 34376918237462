.has-dropdown {
  position: relative;
  width: 100%;
  max-width: 280px;
}

.dropdown {
  display: none;
  position: absolute;
  z-index: 10;
  right: 0;
  left: 0;
  top: 100%;
  margin: 0;
  padding: 0.5rem 0 1rem;
  animation: slide-down .15s ease 1;
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid var(--border-gray);
  background: var(--white);
  transform: translateY(.2rem);

  &-toggle {
    display: block;
  }

  &-item {
    color: var(--stone);

    &-heading,
    &-subheading {
      font-weight: 600;
      padding: 0.25rem 0.5rem;
    }

    &-heading { color: var(--stone); }

    &-subheading {
      color: var(--blue-chill);
      padding-left: 1rem;
    }
  }

  &-link {
    display: block;
    padding: 0.25rem 1rem;
    color: var(--text-color);

    &:hover {
      background-color: var(--sky-blue);
      text-decoration: none;
    }
  }
}

.dropdown-toggle:focus + .dropdown,
.dropdown:hover {
  display: block;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-highlight {
  font-weight: bold;
  color: var(--gray);
}

// Vue Select overrides
// https://vue-select.org/
.v-select {
  background-color: white;

  &.vs--single {
    height: 38px;

    .vs__dropdown-toggle {
      height: 38px;
    }

  }

  .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
  }

  .vs__dropdown-toggle {
    padding-top: 1px;
    padding-bottom: 5px;
    border: 2px solid var(--border-gray);
    border-radius: 0;
  }

  .vs__dropdown-menu {
    min-width: 128px;
  }

  .vs__actions {
    padding: 0 10px;
    border-left: 2px solid var(--border-gray);
    margin-top: 6px;
    margin-bottom: 2px;
  }


  .vs__selected-options {
    padding: 0 6px;
    min-width: calc(100% - 50px);

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      opacity: 0.5;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      opacity: 0.5;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      opacity: 0.5;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      opacity: 0.5;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      opacity: 0.5;
    }
    ::placeholder { /* Most modern browsers support this now. */
      opacity: 0.5;
    }
  }

  .vs__dropdown-option {
    color: var(--stone);

    &--highlight {
      background: none;
    }

    &:hover {
      background: var(--sky-blue);
    }

    &--disabled,
    &--disabled .vs-item {
      color: var(--gray-60);

      &:hover {
        background: none;
      }
    }
  }

  .vs-item {
    color: var(--stone);
    padding-left: 1.5rem;

    &-heading {
      color: var(--stone);
      font-weight: 700;
      padding: 0.25rem 0;
    }

    &-subheading {
      font-weight: 600;
      padding: 0.25rem 0.5rem;
      color: var(--blue-chill);
    }

    &.ml-0 {
      padding-left: 0 !important;
    }
  }
}
