
.graph {
  &-list {
    list-style: none;
    margin: 0 -1rem;
    padding: 1rem 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-item {
    flex: 1 1 100%;
    margin-bottom: 2rem;
    padding: 1rem;

    background: var(--blue-gradient-3);
    border-bottom: 1px solid var(--blue-chill-30);
  }

  &-caption {
    max-width: 600px;
    margin-top: 1rem;
    font-size: 0.75rem;
  }

  &-group {
    flex: 1 0 100%;
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: var(--blue-gradient-3);

    &-title {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  @media screen and (min-width: 840px) {
    &-item {
      flex: 0 1 calc(50% - 1rem);
    }
  }
}

.data-source {
  padding: 0.5rem 1.5rem;
  font-size: .75rem;

  &-title {
    font-weight: 700;
    margin-right: 0.5rem;
  }

  &-link {
    font-size: .75rem;
  }
}

.footnotes {
  margin-top: 2rem;
  font-size: 0.75rem;

  &-title {
    font-size: .875rem;
    font-weight: 700;
  }
}

.footnote {
  display: flex;
  width: 100%;
  max-width: 800px;

  strong {
    color: var(--gray);
    min-width: 20px;
    margin-right: 8px;
  }

  &-group {
    margin-bottom: 0.25rem;
  }
}

.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;

  p {
    text-align: center;
    font-size: 1.25rem;
  }
}