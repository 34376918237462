.share {
  &-copy {
    width: 100%;
    display: flex;
    justify-content: center;

    .button {
      align-self: flex-end;
      margin-left: 1rem;
    }
  }

  &-social {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 2rem;

    .social-button:not(:first-child) {
      margin-left: 3rem;
    }
  }

  @media screen and (max-width: 767px) {
    &-social {
      flex-direction: column;
      align-items: center;
    }

    &-social .social-button:not(:first-child) {
      margin: 2rem 0 0;
    }
  }
}
