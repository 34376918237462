.highcharts {
  font-family: var(--heading-font-family);
}

.highcharts-title {
  font-family: var(--heading-font-family);
  font-size: 22px;
  font-weight: 700;
}

.highcharts-subtitle {
  font-family: var(--font-family);
}

.highcharts-axis-title {
  font-weight: 700;
}

.highcharts-plot-line {
  opacity: .4;
}