dialog,
.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  max-height: 100vh;
  margin: auto;
  border: 0 none;
  padding: 0;
  background: var(--white);
  display: block;
  z-index: 2;

  &.focused {
    max-width: 640px;
  }

  @media screen and (min-width: 768px) {
    max-width: calc(100% - 40px);
    max-height: calc(100vh - 4rem);
  }

  @media screen and (min-width: 1024px) {
    max-width: 960px;
  }
}

dialog:not([open]) {
  display: none;
}

dialog::backdrop {
  background: #1B2A4685;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}

dialog .close-button,
.dialog .close-button {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  z-index: 1;

  &:focus {
    outline: none;
  }
}

.scroll-wrap {
  height: min-content;
  max-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties no-vendor-prefixes
  scrollbar-width: thin;

  @media screen and (min-width: 768px) {
    max-height: calc(100vh - 4rem);
  }
}

.city-list {
  list-style: none;
  margin: 0;
  padding: 0;
  columns: 3;
  column-gap: 5rem;
  column-width: 200px;
  font-family: var(--heading-font-family);
  font-weight: 600;
  font-size: 1.25rem;
}

.modal {
  &-header {
    padding: 2rem;
    padding-top: 1.3rem;

    &-filter {
      background-color: var(--sky-blue-20);
    }

    &-heading {
      margin-bottom: 0;
    }
  }

  &-heading {
    text-align: center;
    font-size: 1.625rem;
    font-family: var(--alt-font-family);
    font-weight: 400;
    margin-top: 0;
  }

  &-body {
    padding: 2rem;
  }
}


// Toast notification version of Dialog
.toast {
  top: auto;
  bottom: 20px;
  left: auto;

  display: flex;
  flex-flow: column;

  width: clamp(360px, 100%, 480px);
  border: 1px solid var(--sky-blue);
  box-shadow: 0 0 30px rgba(27, 42, 70, 0.30);

  h1, h2 {
    margin: 0 0 .3rem;
    font-size: 1.4rem;
  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-image: var(--blue-gradient-3);
  }

  &-body {
    padding: 1.5rem;
    font-size: .95rem;
  }

  &-actions {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    margin-top: 1rem;
  }
}

@media (min-width: 440px) {
  .toast {
    flex-flow: row wrap;
    right: 20px;

    &-image {
      flex: 0 1 100px;
    }

    &-body {
      flex: 1;
    }
  }
}