.pagination,
.pagination ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pagination {
  margin-top: 2rem;
}

.pagination ul {
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.pagination li {
  list-style: none;
}

.pagination .prev {
  margin-right: auto;
}

.pagination .next {
  margin-left: auto;
}