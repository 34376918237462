.metrics-dialog-groups {
  column-gap: 40px;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 768px) {
    &:not(.filtered) {
      columns: 2;
    }
  }

  @media screen and (min-width: 840px) {
    &:not(.filtered) {
      columns: 3;
    }
  }
}

.metrics-dialog-sub-groups {
  list-style: none;
  padding-left: 1rem;

  @media screen and (min-width: 768px) {
    &.filtered {
      columns: 2;
    }
  }

  @media screen and (min-width: 840px) {
    &.filtered {
      columns: 3;
    }
  }
}

.metrics-dialog-sub-group {
  padding-bottom: .5rem;
  break-inside: avoid;
}

.metrics-dialog-group {
  break-inside: avoid;
  padding-bottom: 1rem;
}

h5.metrics-dialog-category {
  color: var(--stone);
  font-weight: 700;
  padding: 0.5rem 0;
  margin: 0;
}

h6.metrics-dialog-subcategory {
  font-weight: 600;
  color: var(--blue-chill);
  padding: 0.5rem 0;
  margin: 0;
}

.metrics-dialog-metric {
  display: block;
  width: 100%;
  color: var(--stone);
  padding: 0.5rem 0.25rem 0.5rem 1rem;
  margin: 0;
  font-size: 14px;

  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;

  &:not(.disabled):hover {
    background-color: var(--blue-chill);
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// Conflicting close-button class from v-select
.vs__clear .close-button {
  position: unset;

  &::after {
    color: rgb(27, 42, 70);
  }
}