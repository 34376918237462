/* PT Sans 400 and 700: for body text */
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* US 101: All-caps brand/accent font */
@font-face {
    font-family: 'US101regular';
    src: url('../fonts/US101/us101-webfont.woff2') format('woff2'),
         url('../fonts/US101/us101-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* News Gothic 500, 700, 900: Headings and Form fields */
@font-face {
    font-family: 'NewsGothic';
    src: url('../fonts/NewsGothicLTStdMedium/font.woff2') format('woff2'),
         url('../fonts/NewsGothicLTStdMedium/font.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'NewsGothic';
    src: url('../fonts/NewsGothicLTStdBold/font.woff2') format('woff2'),
         url('../fonts/NewsGothicLTStdBold/font.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NewsGothic';
    src: url('../fonts/NewsGothicLTStdBlack/font.woff2') format('woff2'),
         url('../fonts/NewsGothicLTStdBlack/font.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
