.tooltip {
  position: relative;
  display: inline-block;

  .tooltip-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 1ch;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    background-color: var(--stone);
    color: white;
  }

  .tooltip-text {
    visibility: hidden;
    width: 160px;
    bottom: 100%;
    left: 50%;
    margin-left: -80px;
    background-color: var(--stone);
    color: white;
    text-align: center;
    border-radius: 4px;
    padding: 0.5rem;
    position: absolute;
    z-index: 100;
    font-size: .875rem;
    font-weight: bold;
    text-transform: none;
    opacity: 1;
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
}
