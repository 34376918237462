.select-group {
  &-wrap {
    position: relative;
    background: var(--off-pearl-20);
    margin: 0 -1rem;
    padding: 1rem;
    border-bottom: 1px solid var(--blue-chill-05);
  }

  &-labeled {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    @media screen and (min-width: 1120px) {
      margin-left: 42px;
    }
  }

  &-step {
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    height: 42px;
    display: block;
    background-color: var(--blue-chill);
    color: white;
    font-size: 1.5rem;
    text-align: center;

    @media screen and (min-width: 1120px) {
      left: -42px;
    }
  }

  &-label {
    margin: 0;
    margin-left: 42px;

    @media screen and (min-width: 1120px) {
      margin-left: 0;
    }
  }

  &-list {
    list-style: none;
    margin: 0 auto;
    width: 100%;
    padding: 0;

    @media (min-width: 960px) {
      margin: 1rem auto 0 0;
    }
  }

  &-item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1rem 50px 1rem  1rem;
    margin: .5rem -1rem 1rem 0;
    position: relative;

    .remove-button {
      position: absolute;
      right: 1rem;
      top: calc(50% - 1.25rem);
      transform: translateY(-50%);
    }

    &-button {
      margin-top: 1.6rem;
      margin-bottom: 4px;
      height: 36px;
    }

    .col {
      display: flex;
      align-items: center;
      margin: 0 1rem 1rem 0;

      &.w1 {
        flex: 1 1 calc(50% - 2rem);
      }

      &.w2 {
        flex: 1 1 100%;
      }

      &.min {
        flex: 1 0 128px;
      }

      input,
      select,
      .has-dropdown {
        max-width: none;
      }

      .field {
        width: 100%;
      }
    }

    .icon-button {
      align-self: flex-end;
      margin: 0 0 .25rem .5rem;
    }

    @media screen and (min-width: 960px) {
      flex-wrap: nowrap;
      border: 0 none;
      padding: 0 50px 0 0;
      margin: 0 0 1rem 0;

      &:nth-child(even) {
        background: transparent;
      }

      &:not(:first-child) label {
        display: none;
      }

      .col:last-child {
        margin-right: 0;
      }
    }

  }

  &-add-wrap {
    text-align: center;
    margin: 1rem 0 -2rem;
    grid-column: 2;
  }
}
