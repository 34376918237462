.subscribe-promo {
  padding: 1.5rem;
  background-color: var(--off-pearl-20);
}

.subscribe-promo h1,
.subscribe-promo h2 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.subscribe-promo .field {
  flex: 1 0 20ch;
  margin-bottom: .5rem;
}

.subscribe-promo p {
  margin-bottom: 1rem;
}

.subscribe-promo.has-image {
  position: relative;

  background-color: transparent;
  background-image: var(--blue-gradient-3);

  @media (min-width: 500px) {
    --image-size: 72px;

    padding-left: calc(var(--image-size) + 1rem);

    &::before {
      position: absolute;
      top: 1.25rem;
      left: -1rem;
      display: block;
      width: var(--image-size);
      height: var(--image-size);
      background-color: var(--blue-chill);
      background-image: url(/img/mail-icon.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 60%;
      content: "";
    }
  }
}

#subscribe {
  max-width: 500px;
}

#subscribe .subscribe-promo.has-image {
  &::before { left: 0; }
}
