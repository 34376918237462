.post {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  padding: 2rem 0;
  border-bottom: 1px solid var(--blue-chill-10);
}

.post-img {
  width: 100%;
  align-self: center;

  a img {
    box-shadow: 0 0 4px var(--blue-chill-30);
  }
}

.post-header {
  display: flex;
  justify-content: space-between;
  gap: .75rem 1.5rem;
}

.post-timestamp {
  display: block;
  margin-bottom: .75rem;
  font-size: .9rem;
  font-weight: 600;
  opacity: 0.6;
}

.post-heading {
  margin: 0;
}

.post-heading + * {
  margin-top: .5rem;
}

.post-content {
  word-break: break-word;

  p:last-child {
    margin-bottom: 0;
  }

  a {
    @include link-effects;
  }
}

.post-content-with-image {
  display: flex;
  justify-content: space-between;
}

.post-body {
  flex: 1 1 100%;
}

@media (min-width: 840px) {
  .post {
    flex-direction: row;
  }

  .post-img {
    flex: 1 0 400px;
  }
}