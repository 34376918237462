html {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: 1.6;
  color: var(--text-color);

  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1rem 0;
  line-height: 1.2;
  font-family: var(--heading-font-family);
  color: var(--heading-color);
}

p {
  margin-top: 0;
}

h1 {font-size: 2em;}
h2 {font-size: 1.6em;}
h3 {font-size: 1.5em;}
h4 {font-size: 1.4em;}
h5 {font-size: 1.2em;}
h6 {font-size: 1em;}


.alt-font-family {
  font-family: var(--alt-font-family);
}

.eyebrow {
  font-family: var(--alt-font-family);
  color: var(--gray);
  font-size: 1.25rem;
  margin: 0;
  opacity: 0.8;
}

.section-heading {
  font-size: 1.75rem;
}

a {
  text-decoration: none;
  color: var(--link-color);

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--link-underline-color);
    text-decoration-skip: skip;
  }

  &.disabled {
    color: var(--gray-60);
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
}

.content a {
  @include link-effects;
}

.page-heading {
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 700;
  text-shadow: 1px 1px 1px #FFFFFF;
}

.page-subheading {
  font-size: 20px;
  font-family: var(--alt-font-family);
  text-transform: uppercase;
  color: var(--stone-80);
}

.link-more {
  @include link-effects;
  display: inline-flex;
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: none;

  &::after {
    display: inline-block;
    height: 0.75rem;
    width: 1rem;
    margin-bottom: -3px;
    margin-left: 0.5ch;
    align-self: center;
    background-color: currentColor;
  
    -webkit-mask-image: url(/img/caret-icon.svg);
    mask-image: url(/img/caret-icon.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
  
    content: '';
  }
}

@media screen and (min-width: 840px) {
  .page-heading {
    font-size: 2.875rem;
  }
}
